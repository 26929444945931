import React from 'react';
import { ThemeContext } from '../ThemeContext';
import { Menu } from '../Menu/Menu';

import * as styles from './Header.module.css';

export const Header = ({ headerTransparent, hasAlertBar, yellowBusiness, sections, cart }) => {
  const { bg, text } = React.useContext(ThemeContext);
  const [heightOfHeader, setHeightOfHeader] = React.useState(88);

  const headerEl = React.useRef(null);

  React.useLayoutEffect(()=>{
    // this is to identify the height of the menu to enable the hero banner to overlap
    function getHeaderHeight() {
      setHeightOfHeader(headerEl?.current?.clientHeight || 0);
    }

    getHeaderHeight();
    window.addEventListener('resize', getHeaderHeight);

    function addScrollDownClass() {
      var reachedThreshold = window.pageYOffset > document.querySelector('#header')?.clientHeight;
      var scrollDownClassExist = document.querySelector('.header--active');

      if (reachedThreshold && !scrollDownClassExist) {
        document.querySelector('#header')?.classList.add('header--active');
      }

      if (window.pageYOffset === 0 && scrollDownClassExist) {
        document.querySelector('#header')?.classList.remove('header--active');
        window.setTimeout(() => {
          getHeaderHeight();
        }, 300)
      }
    }

    addScrollDownClass();
    window.addEventListener('scroll', addScrollDownClass)

    return () => {
      window.removeEventListener('scroll', addScrollDownClass);
      window.removeEventListener('resize', getHeaderHeight);
    }
  }, []);

  return (
    <header
      id="header"
      ref={headerEl}
      className={`
        py-3 md:py-5 shadow-md z-50 top-0 w-full sticky 
        ${bg('highlight-dark')} ${text('highlight-light')} 
        ${styles['header']}
        ${headerTransparent && styles['headerTransparent']}
      `}
      style={headerTransparent && {
        marginBottom: `-${heightOfHeader}px`,
      }}
    >
      <div className="max-width-wrapper">
        <Menu
          className=""
          yellowBusiness={yellowBusiness}
          sections={sections}
          cart={cart}
        />
      </div>
    </header>
  );
};


import React from 'react';

import { HTMLPageHeader } from '../HTMLPageHeader/HTMLPageHeader';
import { Footer } from '../Footer/Footer';
import { ThemeContext } from '../ThemeContext';
import AlertBar from '../AlertBar';
import { Header } from '../Header/Header';

export const Layout = ({
    headerTransparent, 
    children, 
    pageName = 'Home',
    yellowBusiness,
    sections,
    cart,
    isEcommerce,
    keywords = [],
    metaDescription = '',
}) => {
  const { bg, text } = React.useContext(ThemeContext);

  const hasAlertBar = !!yellowBusiness?.website?.alertBar?.text;

  return (
    <div className="min-h-screen min-w-full">
      {hasAlertBar && (<AlertBar alertBar={yellowBusiness?.website?.alertBar || null} />)}

      <div className={`flex flex-col items-center ${bg('base-light')} ${text('base-dark')} antialiased`}>
        <Header
          headerTransparent={headerTransparent}
          hasAlertBar={hasAlertBar}
          yellowBusiness={yellowBusiness}
          sections={sections}
          cart={cart}
        />

        <HTMLPageHeader isEcommerce={isEcommerce} title={pageName} business={yellowBusiness} keywords={keywords} metaDescription={metaDescription} />

        <main className={`flex-1 max-width-wrapper flex flex-col space-y-10 lg:space-y-16 ${!headerTransparent && 'pt-10'} pb-10 lg:pb-16`}>
          {children}
        </main>
        <Footer yellowBusiness={yellowBusiness} isEcommerce={isEcommerce} />
      </div>
    </div>
  );
}
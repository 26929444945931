import React, { FC, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import SnackbarProvider from 'react-simple-snackbar';
import ReCAPTCHA from 'react-google-recaptcha';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faExclamationCircle,
  faPaperPlane,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import {
  fireContactAnalyticsEvent,
  logError,
  useSafeSnackbar,
} from '@websites/utils';
import { ThemeContext } from '@websites/components';

interface IFormInput {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  message: string;
  subject: string;
}

/** as per RFC 5322 Official Standard */
// eslint-disable-next-line no-useless-escape
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// TODO refactor out to separate component in package/utils, and then reuse in checkout button in ecommerce
export const ProgressButton: FC<{
  label: string;
  type: 'button' | 'submit';
  faIcon?;
  onClick: () => any;
}> = ({
  label, type, faIcon, onClick,
}) => {
  const [startedDoingAction, setStartedDoingAction] = useState<boolean>(false);
  const {
    bg, text, rounded, border,
  } = React.useContext(ThemeContext);

  const handleClick = async () => {
    setStartedDoingAction(true);
    await onClick();
    setStartedDoingAction(false);
  };

  const buttonColors = () => (
    `border ${border('highlight-dark')} ${
      startedDoingAction
        ? `${bg('highlight-dark')} ${text('highlight-light')}`
        : `${bg('highlight-dark')} ${text(
          'highlight-light',
        )} active:opacity-75 hover:opacity-90`}`
  );

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={`${buttonColors()} w-full sm:w-32 h-10 py-3 px-10 flex justify-center items-center ${rounded(
        'sm',
      )} text-sm relative`}
      onClick={handleClick}
      disabled={startedDoingAction}
    >
      {!startedDoingAction && (
        <>
          <span className="uppercase tracking-widest">{label}</span>
          {faIcon && (
            <div className="md:absolute ml-2 right-1 flex items-center">
              <FontAwesomeIcon icon={faIcon} />
            </div>
          )}
        </>
      )}
      {startedDoingAction && (
        <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
      )}
    </button>
  );
};

const WrappedContactForm = ({ onSubmit }: { onSubmit?: (any) => void }) => {
  const {
    register,
    handleSubmit,
    getValues,
    // @ts-ignore
    errors,
    reset,
  } = useForm<IFormInput>({
    mode: 'all',
  });
  const { text, rounded } = React.useContext(ThemeContext);
  const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);
  const anyInputTouched = useRef(false);

  const reCaptchaRef = useRef<ReCAPTCHA>();

  const inputLabelClass = `${text(
    'base-dark',
  )} opacity-50 text-xs uppercase tracking-wide`;

  const inputClass = (errMsg) => {
    let inputClasses = `w-full mb-2 shadow-inner ${rounded('md')}`;
    if (isSendingMessage) {
      inputClasses += ' bg-gray-100'; // disabled look
    }
    inputClasses += errMsg
      ? ' border border-red-400 bg-red-100'
      : ' border border-gray-300';
    return inputClasses;
  };

  const MESSAGE_CHAR_LIMIT = 500;

  const sendAnalyticsEventOnFirstInputFocus = () => {
    if (!anyInputTouched.current) {
      fireContactAnalyticsEvent('contact_form_touched', 'Contact form touched');
      anyInputTouched.current = true;
    }
  };

  const { openSnackbar } = useSafeSnackbar();

  const showMessageSentNotification = () => {
    if (openSnackbar) {
      openSnackbar(
        <div>
          <FontAwesomeIcon icon={faPaperPlane} className="text-gray-500 mr-2" />
          <span className="text-gray-700">Message sent.</span>
        </div>,
        2000,
      );
    }
  };

  const showErrorWhileSendingMessageNotification = () => {
    if (openSnackbar) {
      openSnackbar(
        <div>
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="text-red-500 mr-2"
          />
          <span className="text-gray-700">
            Message could not be sent. Please try again later.
          </span>
        </div>,
        2000,
      );
    }
  };

  return (
    <div className="w-full">
      <form
        className="flex flex-col w-full space-y-2 z-10"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="flex flex-col w-full">
          <div className="w-full">
            <div className="flex flex-col md:flex-row w-full md:space-y-0 md:space-x-2">
              <label className="w-full">
                <span className={inputLabelClass}>
                  First name
                  <span className="text-red-400 ml-1 text-sm">*</span>
                </span>
                <input
                  className={inputClass(errors?.firstName?.message)}
                  name="firstName"
                  type="text"
                  ref={register({
                    required: 'Please enter a first name',
                    maxLength: 20,
                  })}
                  onFocus={sendAnalyticsEventOnFirstInputFocus}
                  disabled={isSendingMessage}
                />
              </label>
              <label className="w-full">
                <span className={inputLabelClass}>
                  Last name
                  <span className="text-red-400 ml-1 text-sm">*</span>
                </span>
                <input
                  className={inputClass(errors?.lastName?.message)}
                  name="lastName"
                  type="text"
                  ref={register({
                    required: 'Please enter a last name',
                    maxLength: 20,
                  })}
                  onFocus={sendAnalyticsEventOnFirstInputFocus}
                  disabled={isSendingMessage}
                />
              </label>
            </div>
            <div className="flex flex-col md:flex-row w-full md:space-y-0 md:space-x-2">
              <label className="w-full">
                <span className={inputLabelClass}>
                  Email
                  <span className="text-red-400 ml-1 text-sm">*</span>
                </span>
                <input
                  className={inputClass(errors?.email?.message)}
                  name="email"
                  type="email"
                  ref={register({
                    required: 'Please enter a email',
                    maxLength: 50,
                    pattern: EMAIL_REGEX,
                  })}
                  onFocus={sendAnalyticsEventOnFirstInputFocus}
                  disabled={isSendingMessage}
                />
              </label>
              <label className="w-full">
                <span className={inputLabelClass}>Phone</span>
                <input
                  className={inputClass(errors?.phone?.message)}
                  name="phone"
                  type="tel"
                  ref={register({ maxLength: 30 })}
                  onFocus={sendAnalyticsEventOnFirstInputFocus}
                  disabled={isSendingMessage}
                />
              </label>
            </div>
            <div className="w-full md:space-y-0 md:space-x-2">
              <label htmlFor="subjectInput" className="flex flex-col">
                <span className={inputLabelClass}>Subject</span>
                <input
                  className={inputClass(errors?.phone?.message)}
                  id="subjectInput"
                  type="text"
                  name="subject"
                  ref={register({ maxLength: 200 })}
                  maxLength={200}
                  onFocus={sendAnalyticsEventOnFirstInputFocus}
                  disabled={isSendingMessage}
                />
              </label>
            </div>
            <label className="flex flex-col h-full">
              <span className={inputLabelClass}>
                Message
                <span className="text-red-400 ml-1 text-sm">*</span>
                {getValues().message?.length > MESSAGE_CHAR_LIMIT && (
                  <span
                    className={`ml-1 text-xs normal-case font-normal ${text(
                      'base-dark',
                    )} opacity-75`}
                  >
                    (max
                    {' '}
                    {MESSAGE_CHAR_LIMIT}
                    {' '}
                    letters)
                  </span>
                )}
              </span>
              <textarea
                name="message"
                rows={6}
                // @ts-ignore
                ref={register({
                  required: 'Please enter a message',
                  maxLength: MESSAGE_CHAR_LIMIT,
                })}
                onFocus={sendAnalyticsEventOnFirstInputFocus}
                className={
                  `${inputClass(
                    errors.message?.message
                      || getValues().message?.length > MESSAGE_CHAR_LIMIT,
                  )} h-full`
                }
                disabled={isSendingMessage}
              />
            </label>
          </div>
        </div>
        <div className="flex flex-col-reverse sm:items-center sm:flex-row sm:justify-between">
          <div
            className={`my-3 sm:my-0 overflow-hidden shadow-sm ${rounded(
              'md',
            )}`}
            style={{ width: 'max-content' }}
          >
            <ReCAPTCHA
              // @ts-ignore
              ref={reCaptchaRef}
              size="invisible"
              badge="inline"
              theme="light"
              sitekey={process.env.GATSBY_RECAPTCHA_V2_SITE_KEY ?? '1'}
            />
          </div>
          <ProgressButton
            label="Submit"
            type="submit"
            faIcon={faChevronRight}
            onClick={handleSubmit(async (formData) => {
              if (typeof onSubmit !== 'function') return;

              try {
                setIsSendingMessage(true);
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const reCaptchaToken = await reCaptchaRef.current!.executeAsync();

                await onSubmit({
                  ...formData,
                  gRecaptchaToken: reCaptchaToken,
                });

                fireContactAnalyticsEvent(
                  'contact_form_submitted',
                  'Contact form submitted',
                );
                reset();
                setIsSendingMessage(false);
                showMessageSentNotification();
              } catch (err) {
                setIsSendingMessage(false);
                logError(
                  'Error while sending message from contact us form',
                  err,
                );
                showErrorWhileSendingMessageNotification();
              }
            })}
          />
        </div>
      </form>
    </div>
  );
};

WrappedContactForm.defaultProps = {
  onSubmit: () => {},
};

const ContactForm = ({
  onSubmit,
}: { onSubmit?: (any) => void }) => (
  <SnackbarProvider>
    <WrappedContactForm onSubmit={onSubmit} />
  </SnackbarProvider>
);

ContactForm.defaultProps = {
  onSubmit: () => {},
};

export default ContactForm;

import * as R from 'ramda';

const weekDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const getHoursForDay = (day) => (hours) => R.filter(
  R.compose(
    R.includes(day),
    R.prop('dayOfWeek'),
  ),
)(hours);

export const getOpeningHours = (hours) => R.compose(
  R.reject(R.compose(
    R.equals(0),
    R.length,
    R.prop('hours'),
  )),
  R.map((day) => ({
    day,
    hours: getHoursForDay(day)(hours),
  })),
)(weekDays);

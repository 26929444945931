import React from 'react';

import { Section } from "../Section/Section";
import { RichTextHTML } from "../RichTextHTML";

export const SectionAboutBusiness = ({section, yellowBusiness}) => {
  return (
    <>
      {section && (
        <Section section={section}>
          <RichTextHTML className="w-full text-md">
            {yellowBusiness?.description}
          </RichTextHTML>
        </Section>
      )}
    </>
  )
}

import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt as phoneIcon } from '@fortawesome/free-solid-svg-icons';

import { fireClickAnalyticsEvent, fireContactAnalyticsEvent, formattedDefaultPhoneNumber } from '@websites/utils';
import { ThemeContext } from '../ThemeContext';
import { LogoOrName } from '../LogoOrName/LogoOrName';
import { MenuHeaderToggleButton } from '../MenuHeaderToggleButton/MenuHeaderToggleButton';
import HeaderCartIcon from '../HeaderCartIcon/HeaderCartIcon';

export const Menu = ({ yellowBusiness, sections, cart, className }) => {
  const formattedPhoneNumber = formattedDefaultPhoneNumber(yellowBusiness);
  const { text } = React.useContext(ThemeContext);

  return (
    <div
      className={`${className}
        w-full
        mx-auto
        text-sm md:text-base lg:text-lg ${text('base-light')}
        flex justify-between`}
    >
      <LogoOrName
        className="nav__logo"
        businessLogo={yellowBusiness?.logo}
        name={yellowBusiness?.name}
      />
      <div className="flex items-center">
        <nav className={`
          hidden md:flex items-center
          ${!cart && 'space-x-3 lg:space-x-5'}
          ${cart && 'space-x-5 lg:space-x-8'}
        `}>
          {sections && Object.values(sections).filter(Boolean).map((section, sectionIndex) => {
            return (
              <Link
                className={`
                  hover:underline
                  ${!cart && 'text-base'}
                  ${cart && 'font-sans text-sm uppercase tracking-widest font-bold'}
                `}
                to={section?.link || '/'}
                onClick={() => fireClickAnalyticsEvent('link_click', section?.name)}
                key={section?.name || sectionIndex}
              >
                {section?.name}
              </Link>
            )
          })}
        </nav>
        {(cart) && (
          <HeaderCartIcon className="ml-4 lg:ml-10" cart={cart} />
        )}
        {(!cart && formattedPhoneNumber) && (
          <a
            className="ml-4 lg:ml-5 text-right lg:font-bold flex items-center space-x-2"
            rel="noopener"
            href={`tel:${formattedPhoneNumber.replace(' ', '')}`}
            onClick={() => {
              fireContactAnalyticsEvent('contact_link_clicked', 'phone');
            }}
          >
            <FontAwesomeIcon icon={phoneIcon} />
            <span>{formattedPhoneNumber}</span>
          </a>
        )}
        <div className="block md:hidden ml-4">
          <MenuHeaderToggleButton
            sections={sections}
            yellowBusiness={yellowBusiness}
          />
        </div>
      </div>
    </div>
  );
};
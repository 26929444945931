import {
  IServersideBusiness,
  IYellowBusiness,
  ThemeVariant,
} from '@websites/model';
import { yellowBusinessToLocalBusinessJSONLD } from './yellowBusinessToLocalBusinessJSONLD';

export const serverBusinessToClientBusiness: (args: {
  serverBusiness: IServersideBusiness;
  defaultTheme: ThemeVariant;
  overrideHeadline?: string;
  overrideSubHeadline?: string;
}) => IYellowBusiness = ({
  serverBusiness,
  defaultTheme,
  overrideHeadline,
  overrideSubHeadline,
}) => {
  const {
    headline,
    alternativeHeadline,
    googleSiteVerificationId,
    alertBar,
    faqs,
    slug,
    greeting,
    primaryImageOfPage,
    heroHeaderType,
    slideshow,
    gatsbyImagePrimaryImageOfPage,
    gallery,
    galleryGatsbyList,
  } = serverBusiness.providerConfiguration.yellowWebsite || {};

  const assets = serverBusiness?.assets;

  let transformedGallery = [];

  if (gallery && galleryGatsbyList && assets) {
    transformedGallery = gallery.map((image, imageIndex)=>{
      const [foundAsset] = assets.filter((asset)=>{return asset.contentUrl === image});

      if (foundAsset) {
        return {
          contentUrl: foundAsset.contentUrl,
          caption: foundAsset.caption,
          classification: foundAsset.classification,
          image: galleryGatsbyList[imageIndex].galleryGatsbyImage,
          aspectRatio: galleryGatsbyList[imageIndex].galleryGatsbyImage?.childImageSharp?.fluid?.aspectRatio,
        }
      } else {
        return null;
      }

    })
  }

  const clientBusiness: IYellowBusiness = {
    name: serverBusiness.details.name,
    description: serverBusiness.details.description,
    locations: serverBusiness.locations,
    products: serverBusiness.products,
    logo: serverBusiness.details.logo,
    socialLinks: serverBusiness.details.sameAs,
    website: {
      headline: overrideHeadline || headline,
      subHeadline: overrideSubHeadline || alternativeHeadline,
      heroHeaderType,
      slideshow,
      slug,
      theme: process.env.GATSBY_WEBSITES_THEME
        ? (`${process.env.GATSBY_WEBSITES_THEME}` as ThemeVariant)
        : defaultTheme,
      faqs,
      greeting,
      coverImage: primaryImageOfPage,
      gatsbyImageCoverImage: gatsbyImagePrimaryImageOfPage,
      googleSiteVerificationId,
      alertBar,
    },
    gallery: transformedGallery.filter(Boolean),
  };

  clientBusiness.website.jsonLD = yellowBusinessToLocalBusinessJSONLD(
    clientBusiness,
  );

  return clientBusiness;
};

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';

import { ILocation } from '@websites/model';
import { fireContactAnalyticsEvent, formatPhoneNumber } from '@websites/utils';

export const Location: React.FC<{ location: ILocation }> = ({ location }) => {
  const addrs = location.address;

  return (
    <address className="flex flex-col not-italic">
      <div className="uppercase tracking-widest font-bold mb-2">
        {location?.hasMap ? (
          <>Location</>
        ) : (
          <>Contact</>
        )}
      </div>
      {(location?.hasMap && addrs) && (
        <>
          <div>
            <span>{addrs.streetNumber}</span>
            <span> {addrs.streetName}</span>
          </div>
          <div>{addrs.sublocality}</div>
          {addrs.administrativeArea !== addrs.locality && (
            <div>{addrs.locality}</div>
          )}
          <div>
            {addrs.administrativeArea} {addrs.postalCode}
          </div>
        </>
      )}
      {location?.telephone && (
        <a
          className="flex items-center space-x-2 pt-3 pb-3"
          rel="noopener"
          href={`tel:${formatPhoneNumber(location.telephone)}`}
          onClick={() => {
            fireContactAnalyticsEvent('contact_link_clicked', 'phone');
          }}
        >
          <FontAwesomeIcon icon={faPhoneAlt} />
          <span>{formatPhoneNumber(location.telephone)}</span>
        </a>
      )}
      {location?.email && (
        <a
          className="flex items-center space-x-2 pt-3 pb-3"
          href={`mailto:${location.email}`}
        >
          <FontAwesomeIcon icon={faEnvelope} />
          <span>{location.email}</span>
        </a>
      )}
    </address>
  );
};

import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faFacebookF,
  faInstagram,
  faSnapchatGhost,
  faGoogle,
  faLinkedinIn,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { ThemeContext } from '@websites/components';
import { fireSocialAnalyticsEvent } from '@websites/utils';
import { ISocial } from '@websites/model/client.model';

export const SocialIcon: FC<{
  social: ISocial;
}> = ({ social }) => {
  let icon;
  let socialName = social.name?.toLowerCase().trim() || 'unnamed';

  if (socialName.includes('twitter')) {
    icon = faTwitter;
  } else if (socialName.includes('facebook')) {
    icon = faFacebookF;
  } else if (socialName.includes('instagram')) {
    icon = faInstagram;
  } else if (socialName.includes('linkedin')) {
    icon = faLinkedinIn;
  } else if (socialName.includes('youtube')) {
    icon = faYoutube;
  } else if (socialName.includes('pintrest')) {
    icon = faInstagram;
  } else if (socialName.includes('snapchat')) {
    icon = faSnapchatGhost;
  } else if (social.url?.includes('g.page') || socialName.includes('google')) {
    icon = faGoogle;
  } else {
    icon = faLink;
  }

  const { text, bg } = React.useContext(ThemeContext);

  return (
    <a
      className={`rounded-full ${bg(
        'highlight-light'
      )} ${text('highlight-dark')} w-10 h-10 hover:${text(
        'accent-dark'
      )} flex justify-center items-center`}
      href={social.url}
      onClick={() => fireSocialAnalyticsEvent(socialName)}
      // eslint-disable-next-line react/jsx-no-target-blank
      target="_blank"
      rel="noopener"
    >
      <FontAwesomeIcon icon={icon} />
      {socialName && <span className="sr-only">icon for {socialName}</span>}
    </a>
  );
};

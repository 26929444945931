import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import SlideshowContent from './SlideshowContent';
import SlideshowImage from './SlideshowImage';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

interface ISlideshow {
  slideshow: any[];
}

const Slideshow = ({ slideshow }: ISlideshow) => {
  const slides = slideshow?.map((slide) => {

    if (!slide.link) return null
    return (
      <SwiperSlide key={slide.link}>
        <div className='relative overflow-hidden w-full'>
          <SlideshowContent slide={slide} />
          <SlideshowImage slide={slide}/>
        </div>
      </SwiperSlide>
    );
  });

  return (
    <Swiper
      modules={[Pagination, Autoplay]}
      autoplay={{ delay: 5000 }}
      loop={true}
      pagination={{
        clickable: true,
      }}
      spaceBetween={50}
      slidesPerView={1}
    >
      {slides}
    </Swiper>
  );
};

export default Slideshow;

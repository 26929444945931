import sanitizeHtml from 'sanitize-html';

export const customSanitizeHTML: (html: string) => string = (html) =>
  sanitizeHtml(html, {
    allowedTags: [
      'a',
      'p',
      'div',
      'span',
      'b',
      'i',
      'em',
      'u',
      'strong',
      'ul',
      'ol',
      'li',
      'sub',
      'sup',
      'table',
      'tbody',
      'td',
      'tfoot',
      'th',
      'thead',
      'tr',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
    ],
    allowedAttributes: {
      a: ['href', 'rel', 'target'],
    },
    transformTags: {
      a: (_, attribs) => ({
        tagName: 'a',
        attribs: {
          ...attribs,
          rel: 'noopener',
          target: '_blank',
        },
      }),
    },
  });

export const justTheText: (html: string) => string = (html) =>
  sanitizeHtml(html, {
    allowedTags: [],
  });

import React from 'react'
import FluidImage from '../FluidImage';

import * as styles from './SlideshowImage.module.css';

interface ISlideshowImage {
    slide: {
      headline: string;
      headlineSize: string;
      desktopContentPosition: string;
      subheadline: string;
      buttonLabel: string;
      buttonLink: string;
      link:string|undefined;
      slidshowGatsbyImage?: any
    };
  }
const SlideshowImage = ({
  slide
}: ISlideshowImage) => {
  const [isMobile, setIsMobile] = React.useState(false);
  
  React.useLayoutEffect(() => {
    function updateSize() {
      setIsMobile(window.innerWidth < 600);
    }
    updateSize();

    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <>
      <div className="inset-0 z-0">
        <FluidImage
          className={`${styles.cover}`}
          image={slide?.slidshowGatsbyImage}
          alt='slide image'
          fit='cover'
        />
        {slide.link && (
          <div
            // cover image darkening overlay
            className="absolute left-0 top-0 bottom-0 right-0 w-full h-full bg-black"
            style={{ opacity: 0.4 }}
          ></div>
        )}
      </div>
    </>
  )
};

export default SlideshowImage
import React from 'react';

import { Section } from "../Section/Section";
import { Locations } from '../Locations/Locations';

export const SectionLocation = ({section, yellowBusiness}) => {
  return (
    <>
      {yellowBusiness?.locations && (
        <Section section={section || { name: 'Location' }}>
          <Locations locations={yellowBusiness?.locations} />
        </Section>
      )}
    </>
  )
}

import React from 'react';
import styled from 'styled-components';
import { customSanitizeHTML } from '@websites/utils';

const StyledRichText = styled.div`
  div {
    padding: 0px 0px 8px 0px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }
  p {
    padding: 0px;
    margin: 0px;
    margin-bottom: 8px;
  }
  a {
    text-decoration: underline;
  }
  th,
  td {
    padding: 0px 24px 8px 0px;
  }
  ul {
    margin-bottom: 8px;
  }
  ol {
    margin-bottom: 8px;
  }
  *:last-child {
    margin-bottom: 0;
  }
`;

/**
 * Renders sanitized and stylized html rich text
 */
export const RichTextHTML = ({ children, ...rest }) => {
  return (
    <StyledRichText
      dangerouslySetInnerHTML={{
        __html: customSanitizeHTML(children),
      }}
      {...rest}
    ></StyledRichText>
  );
};

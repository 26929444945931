import React from 'react';

import { ThemeContext } from '../ThemeContext';

import Gallery from '../Gallery/Gallery';

function SectionGallery({ section, yellowBusiness }) {
  const theme = React.useContext(ThemeContext);
  const { border, text } = theme;

  return (
    <div className="w-full">
      <div className="flex flex-col mb-4 md:mb-6" style={{ flex: 1 }}>
        <div className={`w-20 ${border('highlight-dark')} border-b-4 mb-1`} />
        <h2
          className={`${text('title')} lg:pr-20 ${theme.isSharp
              ? 'text-xl md:text-2lg lg:text-3xl font-title font-semibold uppercase tracking-wider'
              : 'text-xl md:text-2xl lg:text-3xl font-bold'
            }`}
        >
          {section?.name || 'Gallery'}
        </h2>
      </div>
      {(yellowBusiness?.gallery && yellowBusiness?.gallery.length > 0)
        ? <Gallery gallery={yellowBusiness?.gallery} />
        : <p>No images to show.</p>}
    </div>
  );
}

export default SectionGallery;

import { useSnackbar } from 'react-simple-snackbar';
import { isClient } from '.';

/**
 * Returns snackbar if client, else undefined;
 */
export const useSafeSnackbar: () => {
  openSnackbar?: Function;
  closeSnackbar?: Function;
} = () => {
  let openSnackbar;
  let closeSnackbar;

  if (isClient()) {
    const sb = useSnackbar({
      position: 'bottom-right',
      style: {
        backgroundColor: 'white',
        color: 'black',
      },
      closeStyle: {
        display: 'none',
      },
    });
    openSnackbar = sb[0];
    closeSnackbar = sb[1];
  }

  return { openSnackbar, closeSnackbar };
};

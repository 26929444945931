declare const ga;

/**
 * Fires GA event beacon. Subscription ID is tied to this event based on the main GA configuration in gatsby-browser.js.
 */
export const fireAnalyticsEvent = (
  eventCategory: string,
  eventAction: string,
  eventLabel = 'no event label provided'
) => {
  // no ga when viewing in preview mode through an iframe
  if (typeof ga !== 'undefined') {
    ga('send', 'event', eventCategory, eventAction, eventLabel, 1);
  }
};

export const fireContactAnalyticsEvent = (
  eventAction:
    | 'contact_link_clicked'
    | 'contact_form_submitted'
    | 'contact_form_touched',
  eventLabel: string
) => fireAnalyticsEvent('contact', eventAction, eventLabel);

export const fireSocialAnalyticsEvent = (socialNetworkName: string) =>
  fireAnalyticsEvent('social', 'social_link_clicked', socialNetworkName);

export const fireClickAnalyticsEvent = (
  eventAction: 'button_click' | 'link_click',
  eventLabel: string
) => fireAnalyticsEvent('click', eventAction, eventLabel);

// extracted by mini-css-extract-plugin
export var galleryPopup = "GalleryPopup-module--gallery-popup--f7aff";
export var galleryPopupFadeIn = "GalleryPopup-module--galleryPopupFadeIn--22ee3";
export var galleryPopupOpen = "GalleryPopup-module--gallery-popup--open--2a3ba";
export var galleryPopup__carousel = "GalleryPopup-module--gallery-popup__carousel--2c4cf";
export var galleryPopup__close = "GalleryPopup-module--gallery-popup__close--5c529";
export var galleryPopup__image = "GalleryPopup-module--gallery-popup__image--a0888";
export var galleryPopup__imageCaption = "GalleryPopup-module--gallery-popup__image-caption--8b269";
export var galleryPopup__imageWrapper = "GalleryPopup-module--gallery-popup__image-wrapper--47942";
export var galleryPopup__imageWrapperWrapper = "GalleryPopup-module--gallery-popup__image-wrapper-wrapper--482cb";
export var galleryPopup__leftArrow = "GalleryPopup-module--gallery-popup__left-arrow--7cd54";
export var galleryPopup__rightArrow = "GalleryPopup-module--gallery-popup__right-arrow--5472a";
export var sliderWrapper = "GalleryPopup-module--slider-wrapper--e88fd";
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IYellowBusiness } from '@websites/model';
import { ThemeContext } from '@websites/components';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {
  getDefaultLocation,
  fireClickAnalyticsEvent,
} from '@websites/utils';
// @ts-ignore
import yellowIcon from '../../assets/yellow-logo.svg';
// @ts-ignore
import shopifyLogo from '../../assets/shopify_monotone_white.svg';
import { SocialIcon } from '../SocialIcon/SocialIcon';
import { LogoOrName } from '../LogoOrName/LogoOrName';

import { OpeningHours } from './components/OpeningHours';
import { Location } from './components/Location';

import "../../css/layout.css";

export const Footer: React.FC<{
  yellowBusiness: IYellowBusiness;
  isEcommerce?: boolean;
  className?: string;
}> = ({ className, yellowBusiness, isEcommerce = false }) => {
  const { bg, text, border } = React.useContext(ThemeContext);
  const defaultLocation = getDefaultLocation(yellowBusiness);

  return (
    <footer
      className={`${className} w-full ${bg('highlight-dark')} ${text('highlight-light')} ${border('highlight-dark')} flex justify-center text-base`}
    >
      <div className="w-full py-6 lg:py-12 px-4 flex flex-col justify-between space-y-8 md:space-y-0 md:flex-row md:items-start max-width-wrapper md:space-x-5">
        <LogoOrName
          businessLogo={yellowBusiness?.logo}
          name={yellowBusiness?.name}
        />
        {defaultLocation && <Location location={defaultLocation} />}
        {defaultLocation?.openingHours && (
          <OpeningHours hours={defaultLocation.openingHours} />
        )}
        <div className="flex flex-col items-start md:items-end space-y-4">
          <div className="flex space-x-2">
            {yellowBusiness?.socialLinks?.map((social, i) => (
              <SocialIcon key={i} social={social} />
            ))}
          </div>

          <div className="flex flex-col items-start md:items-end text-xs space-y-2">
            <span>Powered by</span>
            <div className="flex items-center space-x-4">
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  isEcommerce
                    ? 'https://yellow.co.nz/our-solutions/online-store/'
                    : 'https://yellow.co.nz/our-solutions/website/'
                }
                onClick={() => fireClickAnalyticsEvent('link_click', 'yellow_home_page')}
              >
                <img
                  style={{ width: '80px' }}
                  alt="Logo for Yellow New Zealand"
                  src={yellowIcon}
                />
              </a>
              {isEcommerce && (
                <>
                  <span>
                    <FontAwesomeIcon icon={faPlus} />
                  </span>
                  <a
                    className="text-xl"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.shopify.com/"
                  >
                    <img
                      src={shopifyLogo}
                      alt="Shopify logo"
                      // https://www.shopify.co.nz/brand-assets
                      style={{ width: '80px' }}
                    />
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

import { IYellowBusiness, IYellowBusinessProduct } from '@websites/model';
import { formatPhoneNumber, getDefaultLocation } from './formatting';
import { justTheText } from './customSanitizeHTML';

export const yellowBusinessToLocalBusinessJSONLD = (b: IYellowBusiness) => {
  const jsonLD: any = {
    '@context': 'http://www.schema.org',
    '@type': 'LocalBusiness',
    name: b.name,
    logo: b.logo,
    image: b.website.coverImage,
    description: b.description ? justTheText(b.description) : '',
  };

  const defaultLocation = getDefaultLocation(b);

  if (defaultLocation) {
    const defaultAddress = defaultLocation.address;
    if (defaultAddress) {
      jsonLD.address = {
        '@type': 'PostalAddress',
        streetAddress: `${defaultAddress.streetNumber} ${
          defaultAddress.streetName
        }${
          defaultAddress.sublocality ? ', ' + defaultAddress.sublocality : ''
        }`.trim(),
        addressLocality: defaultAddress.administrativeArea,
        addressRegion: defaultAddress.locality,
        postalCode: defaultAddress.postalCode,
        addressCountry: 'New Zealand',
      };
    }
    if (defaultLocation.geo) {
      jsonLD.geo = {
        '@type': 'GeoCoordinates',
        ...defaultLocation.geo,
      };
    }
    if (defaultLocation.telephone) {
      jsonLD.telephone = formatPhoneNumber(defaultLocation.telephone);
    }

    if (defaultLocation.openingHours) {
      const ohs = defaultLocation.openingHours;
      let formattedOhs: string[] = [];

      ohs.forEach((oh) => {
        const singleOHString =
          oh.dayOfWeek?.map((dow) => dow.substr(0, 2)).join(', ') +
          ' ' +
          oh.opens +
          '-' +
          oh.closes;
        formattedOhs.push(singleOHString);
      });

      jsonLD.openingHours = formattedOhs.join(' ');
    }
  }

  if (b.socialLinks?.length) {
    jsonLD.sameAs = b.socialLinks.map((social) => social.url);
  }

  if (b.products?.length) {
    jsonLD.hasOfferCatalog = productsToJSONLDOfferCatalog(b.products);
  }

  jsonLD.url = `https://${process.env.GATSBY_WEBSITES_BUILD_URL}/`;

  return jsonLD;
};

export const productsToJSONLDOfferCatalog = (
  products: IYellowBusinessProduct[]
) => {
  const hasOfferCatalog: any = {
    '@type': 'OfferCatalog',
    name: 'Products & Services',
    itemListElement: [],
  };

  products?.map((prod) => {
    hasOfferCatalog.itemListElement.push({
      '@type': 'Offer',
      itemOffered: {
        '@type': 'Service',
        name: prod.name,
        image: prod.image,
      },
    });
  });

  return hasOfferCatalog;
};

import React from 'react';

import { ThemeContext } from '@websites/components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import * as styles from './GalleryPopup.module.css';
import './gallery-carousel.css';

const GalleryPopup = ({
  gallery,
  popupVisible,
  galleryImageIndex,
  galleryTransitionTime,
  closePopup,
  loadFullImage = false,
  onPopupGalleryChange = () => {},
}) => {
  const { bg } = React.useContext(ThemeContext);

  const [transformedGallery, setTransformedGallery] = React.useState(gallery);

  function setImageGalleryAspectRatioStyle(galleryImages) {
    return galleryImages.map((image) => {
      const imageIsHorizontal = image?.aspectRatio > 1;
      let wrapperWrapperStyle = {};

      if (imageIsHorizontal) {
        const maxHeight = window.innerHeight - 105;
        const maxWidth = maxHeight * image?.aspectRatio;

        wrapperWrapperStyle = {
          width: '100%',
          maxWidth: `${maxWidth}px`,
        };
      }

      return {
        ...image,
        contentUrl: image?.contentUrl || image?.src,
        wrapperWrapperStyle,
      };
    });
  }

  React.useEffect(() => {
    setTransformedGallery(setImageGalleryAspectRatioStyle(gallery.filter(Boolean)));
  }, [gallery]);

  return (
    <>
      {/* Popup */}
      <div className={`${styles.galleryPopup} ${popupVisible && styles.galleryPopupOpen} ${bg('highlight-dark')}`}>
        <button type="button" onClick={closePopup} className={styles.galleryPopup__close}>
          <FontAwesomeIcon size="lg" icon={faTimes} />
        </button>
        <Carousel
          className={`gallery-popup__carousel ${styles.galleryPopup__carousel}`}
          stopOnHover
          infiniteLoop
          useKeyboardArrows
          showThumbs={false}
          emulateTouch
          swipeable
          transitionTime={galleryTransitionTime}
          selectedItem={galleryImageIndex}
          onChange={(newImageIndex) => { onPopupGalleryChange(newImageIndex); }}
          renderArrowPrev={(onClickHandler, hasPrev, label) => hasPrev && (
            <button type="button" className={styles.galleryPopup__leftArrow} onClick={onClickHandler} title={label}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
          )}
          renderArrowNext={(onClickHandler, hasNext, label) => hasNext && (
            <button type="button" className={styles.galleryPopup__rightArrow} onClick={onClickHandler} title={label}>
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          )}
          statusFormatter={(current, total) => {
            function addLeadingZero(num) { return (parseInt(num) < 10) ? `0${num}` : num; }
            return `${addLeadingZero(current)}/${addLeadingZero(total)}`;
          }}
        >
          {transformedGallery.map((image, imageIndex) => (
            <div key={`gallery_${imageIndex}`} className={styles.galleryPopup__imageWrapper}>
              <div style={image?.wrapperWrapperStyle} className={styles.galleryPopup__imageWrapperWrapper}>
                {
                  // eslint-disable-next-line no-nested-ternary
                  getImage(image.image) ? (
                    <GatsbyImage
                      className={styles.galleryPopup__image}
                      image={getImage(image.image)!}
                      alt={image?.caption || 'product image'}
                      objectFit="contain"
                    />
                  ) : loadFullImage
                    ? (<img className={styles.galleryPopup__image} src={image?.contentUrl} alt={image?.caption} />)
                    : (<img className={styles.galleryPopup__image} src={image?.tracedSVG} alt={image?.caption} />)
                }
                {image?.caption && (
                  <p className={`${bg('highlight-dark-60')} ${styles.galleryPopup__imageCaption}`}>{image?.caption}</p>
                )}
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );
};

export default GalleryPopup;

import { ILocation } from "@websites/model";
import { formattedAddress } from "@websites/utils";

export const getGoogleMapsIframeParameters = (location: ILocation) => { 
  if (location.geo) {
    return `${location.geo.latitude}, ${location.geo.longitude}`;
  }

  return formattedAddress(location.address);
};

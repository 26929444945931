import React from 'react';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import { fireClickAnalyticsEvent } from '@websites/utils';

/**
 * Shows a shopping cart with current cart item count. On click redirects to shopify-managed checkout screen.
 */
const HeaderCartIcon = ({cart, className}) => (
  <Link
    to="/cart"
    className={`font-sans text-sm uppercase tracking-widest flex space-x-2 items-center 
    ${cart?.numCartItems === 0 ? 'opacity-50' : 'opacity-100'}
    ${className}
  `}
    onClick={() => fireClickAnalyticsEvent('link_click', 'cart')}
  >
    <FontAwesomeIcon icon={faShoppingCart} className="lg:text-lg" />
    {cart?.numCartItems > 0 && <span className="font-bold">{cart?.numCartItems}</span>}
  </Link>
);

export default HeaderCartIcon;

import React from 'react';

import { ThemeContext } from '@websites/components';

import "../../css/layout.css";

const AlertBar = ({ alertBar }) => {
  const { bg, text } = React.useContext(ThemeContext);
  
  return (
    <>
      {alertBar?.text && (
        <>
          {alertBar?.link ? (
            <a href={alertBar?.link} className={`w-full ${bg('alert-bg')} flex justify-center`}>
              <div
                className={`flex justify-center py-3 max-width-wrapper
                ${text('alert-text')}
                font-title tracking-normal text-sm md:text-base text-center`}
              >
                {alertBar?.text}
              </div>
            </a>
          ) : (
            <div className={`w-full ${bg('alert-bg')} flex justify-center`}>
              <div
                className={`flex justify-center py-3 max-width-wrapper
                ${text('alert-text')}
                font-title tracking-normal text-base text-center`}
              >
                {alertBar?.text}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AlertBar;

import React from 'react';
import { ThemeContext } from '../ThemeContext';
import { Link } from 'gatsby';

interface ISlideshowContent {
  slide: {
    headline: string;
    headlineSize: string;
    desktopContentPosition: string;
    subheadline: string;
    buttonLabel: string;
    buttonLink: string;
    link: string | undefined;
  };
}

const SlideshowContent = ({ slide }: ISlideshowContent) => {
  const {
    headline,
    headlineSize,
    subheadline,
    desktopContentPosition,
    buttonLabel,
    buttonLink,
  } = slide;

  const { text } = React.useContext(ThemeContext);

  const getHeadlineFontSizeClass = () => {
    if (headlineSize === 'Small')
      return 'text-2xl md:text-3xl lg:text-4xl xl:text-6xl';
    if (headlineSize === 'Large')
      return 'text-3xl md:text-5xl lg:text-6xl xl:text-8xl';
    return 'text-3xl md:text-4xl lg:text-5xl xl:text-7xl';
  };

  const getContentPositionClass = () => {
    if (desktopContentPosition === 'Top Canter') return 'md:pb-80';
    if (desktopContentPosition === 'Bottom Center') return 'md:pt-80';
    return '';
  };

  return (
    <div
      className={`max-width-wrapper absolute inset-0 z-10 ${text(
        'base-light'
      )}`}
    >
      <div
        className={`w-full h-full flex flex-col items-center justify-center ${getContentPositionClass()}`}
      >
        <h1
          className={`
            font-bold text-center 
            mb-3 md:mb-4 lg:mb-5 xl:mb-6
            ${getHeadlineFontSizeClass()}`}
        >
          {headline}
        </h1>
        <h2
          className={`
            text-center
            text-xl md:text-2xl lg:text-3xl xl:text-4xl`}
        >
          {subheadline}
        </h2>
        {buttonLabel && buttonLink && (
          <div className="mt-8">
            <Link
              to={buttonLink}
              className="border-2 border-solid px-6 py-2 hover:bg-cyan-600 font-bold"
            >
              {buttonLabel}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default SlideshowContent;

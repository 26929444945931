import React from 'react'
import { IBusinessHours } from '@websites/model';
import { formatHourRange } from '@websites/utils/src/formatting';

import { getOpeningHours } from './services/getOpeningHours';

export const OpeningHours = ({ hours }: { hours: IBusinessHours[] }) => {
  const openingHours = getOpeningHours(hours);

  return (
    <div>
      <div className="uppercase tracking-widest font-bold mb-2">
        Opening Hours
      </div>
      <table>
        <tbody>
          {openingHours?.map((({ day, hours }) => (
            <tr key={day}>
              <td className="pr-4" valign="top">{day.substr(0, 3)}</td>
              <td>
                {hours.map(formatHourRange).map((hourLine) => (
                  <React.Fragment key={hourLine}>
                    {hourLine}<br />
                  </React.Fragment>
                ))}
              </td>
            </tr>
          )))}
        </tbody>
      </table>
    </div>
  );
};

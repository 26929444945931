module.exports = {
  spring: {
    "base-dark": "#363636",
    "base-light": "#F8F3E8",
    "highlight-dark": "#FFB54F",
    "highlight-dark-60": "rgba(255, 181, 79,0.6)",
    "highlight-light": "#F8F3E8",
    "accent-dark": "#3F0060",
    "accent-light": "#F8F3E8",
    title: "#3F0060",
    "alert-bg": "#FFB54F",
    "alert-text": "#3F0060",
  },
  candycane: {
    "base-dark": "#2b0816",
    "base-light": "#fdf2f8",
    "highlight-dark": "#831843",
    "highlight-dark-60": "rgba(131,24,67,0.6)",
    "highlight-light": "#fdf2f8",
    "accent-dark": "#DB2777",
    "accent-light": "#fdf2f8",
    title: "#831843",
    "alert-bg": "#831843",
    "alert-text": "#fdf2f8",
  },
  rose: {
    "base-light": "#F5F1EF",
    "base-dark": "#261E19",
    "highlight-dark": "#7D6C62",
    "highlight-dark-60": "rgba(125, 108, 98, 0.6)",
    "highlight-light": "#F5F1EF",
    "accent-dark": "#7D6C62",
    "accent-light": "#F5F1EF",
    title: "#261E19",
    "alert-bg": "#FF99B8",
    "alert-text": "#261E19",
  },
  fresh: {
    "base-light": "#EFF5F0",
    "base-dark": "#19261B",
    "highlight-dark": "#627D67",
    "highlight-dark-60": "rgba(98, 125, 103,0.6)",
    "highlight-light": "#EFF5F0",
    "accent-dark": "#627D67",
    "accent-light": "#EFF5F0",
    title: "#19261B",
    "alert-bg": "#BE92A2",
    "alert-text": "#19261B",
  },
  pro: {
    "base-light": "#EFF1F5",
    "base-dark": "#081D3C",
    "highlight-dark": "#626D7D",
    "highlight-dark-60": "rgba(98, 109, 125,0.6)",
    "highlight-light": "#EFF1F5",
    "accent-dark": "#626D7D",
    "accent-light": "#EFF1F5",
    title: "#081D3C",
    "alert-bg": "#DE2828",
    "alert-text": "#EFF1F5",
  },
  tradie: {
    "base-light": "#F2F2F2",
    "base-dark": "#041430",
    "highlight-dark": "#041430",
    "highlight-dark-60": "rgba(4, 20, 48,0.6)",
    "highlight-light": "#F2F2F2",
    "accent-dark": "#DE2828",
    "accent-light": "#F2F2F2",
    title: "#041430",
    "alert-bg": "#DE2828",
    "alert-text": "#F5F1EF",
  },
};

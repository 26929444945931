import * as React from 'react';
import { initGA } from '@websites/utils';
import { YellowBusinessProvider } from './src/contexts/YellowBusinessContext';

/* eslint-disable no-undef */

// @ts-ignore
import './src/css/style.css';

export const onClientEntry = () => {
  if (process.env.NODE_ENV === 'production') {
    initGA();
  }
};

export const wrapRootElement = ({ element }) => (
  <YellowBusinessProvider>{element}</YellowBusinessProvider>
);

import axios from 'axios';
import { log, logError } from './log';

export const onContactFormSubmit = async (formData) => {
  log('about to post form data to API', formData);
  await axios
    .post(`${process.env.GATSBY_WEBSITES_CONTACT_API}`, formData)
    .then((response) => log('contact api response: ', response))
    .catch((err) => logError('error from contact api: ', err));
  log('finished posting to contact API');
};

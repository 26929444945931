// @ts-nocheck

export const initGA = () => {
  (function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    (i[r] =
      i[r] ||
      function () {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * new Date());
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(
    window,
    document,
    'script',
    'https://www.google-analytics.com/analytics.js',
    'ga'
  );

  /**
   * Assign the root GA property ID
   */
  ga(
    'create',
    `${process.env.GATSBY_WEBSITES_ROOT_GOOGLE_ANALYTICS_PROPERTY_ID}`,
    'auto'
  );
  /**
   * Send a custom dimension or metric with all the hits on a given page
   * https://developers.google.com/analytics/devguides/collection/analyticsjs/custom-dims-mets
   */
  ga('set', 'dimension1', `${process.env.GATSBY_WEBSITES_SUBSCRIPTION_ID}`);
  ga('send', 'pageview');
};

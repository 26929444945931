import slugify from 'slugify';
import { uuid } from 'uuidv4';
import {
  INameable,
  IYellowBusiness,
  IStreetAddress,
  ILocation,
  IBusinessHours,
} from '@websites/model';

export const slug = (nameable: INameable) => {
  const text = nameable?.name?.trim() || uuid();
  return slugify(text, {
    lower: true,
  });
};

export const formattedDefaultPhoneNumber = (
  bus: IYellowBusiness
): string | null => {
  const phone = bus.locations?.find((loc) => loc.default)?.telephone;
  return formatPhoneNumber(phone);
};

export const formatPhoneNumber = (phone) => {
  if (!phone) {
    return null;
  }

  const areaCodeFormatted = phone.areaCode
    ? `${phone.areaCode?.length >= 2 ? '' : '('}${
        (phone.areaCode?.substr(0, 1) === '0' ? '' : '0') + phone.areaCode
      }${phone.areaCode?.length >= 2 ? '' : ')'}`
    : '';

  const restOfTheNumberFormatted =
    `${phone.number}`.substr(0, 3) + ' ' + `${phone.number}`.substr(3);

  const formattedPhoneNumber = `${areaCodeFormatted} ${restOfTheNumberFormatted}`.trim();
  return formattedPhoneNumber;
};

export const formattedAddress = (ad?: IStreetAddress): string => {
  if (!ad) {
    return '';
  }

  return `${ad.streetNumber || ''} ${ad.streetName || ''}
  ${ad.sublocality || ''}
  ${ad.locality || ''}
  ${ad.administrativeArea || ''} ${ad.postalCode || ''}`.trim();
};

// TODO refactor - does not belong in formatting - nothing to do with formatting
export const getDefaultLocation = (
  bus: IYellowBusiness
): ILocation | undefined => bus.locations?.find((loc) => loc.default);

const to12HourTime = (time?: string) => {
  if (time) {
    const militaryHours = Number.parseInt(time.split(':')[0], 10);
    const minutes = time.split(':')[1] === '00' ? '' : time.split(':')[1];
    const hours = militaryHours === 12 ? militaryHours : militaryHours % 12;
    const meridiem = militaryHours === hours ? 'am' : 'pm';
    return `${hours}${minutes ? ':' : ''}${minutes} ${meridiem}`;
  }
  return time;
};

export const formatHourRange = (hours: IBusinessHours) => {
  if (hours.opens === '00:00' && hours.closes === '00:00') {
    return 'Closed';
  } else if (hours.opens === '00:00' && hours.closes === '23:59') {
    return 'Open 24 Hours';
  } else if (hours.conditionsOfAccess === 'By appointment') {
    return 'By appointment';
  }
  return `${to12HourTime(hours.opens)} - ${to12HourTime(hours.closes)}`;
};

const formatSingleOpeningHoursEntry = (
  hours: IBusinessHours
): { days: string; hours: string } => {
  let days: string;
  if (hours.dayOfWeek.length > 1) {
    if (isConsecutiveRangeOfDays(hours.dayOfWeek)) {
      days =
        hours.dayOfWeek[0].substring(0, 3) +
        (hours.dayOfWeek.length === 2 ? ' & ' : ' - ') +
        hours.dayOfWeek[hours.dayOfWeek.length - 1].substring(0, 3);
    } else {
      days = hours.dayOfWeek.map((day) => day.substring(0, 3)).join(', ');
    }
  } else {
    days = hours.dayOfWeek[0].substring(0, 3);
  }

  return {
    days,
    hours: hours.conditionsOfAccess
      ? hours.conditionsOfAccess
      : formatHourRange(hours),
  };
};

export const formatOpeningHours = (
  openingHours: IBusinessHours[]
): { days: string; hours: string }[] | undefined => {
  return openingHours?.map((hoursSpec) =>
    formatSingleOpeningHoursEntry(hoursSpec)
  );
};

const dayToNumber = (day: string): number => {
  switch (day) {
    case 'Monday':
      return 0;
    case 'Tuesday':
      return 1;
    case 'Wednesday':
      return 2;
    case 'Thursday':
      return 3;
    case 'Friday':
      return 4;
    case 'Saturday':
      return 5;
    case 'Sunday':
      return 6;
    default:
      return NaN;
  }
};

const isConsecutiveRangeOfDays = (days: string[]): boolean => {
  let previousDayNumber = dayToNumber(days[0]);
  for (let i = 1; i < days.length; i++) {
    if (dayToNumber(days[i]) === (previousDayNumber + 1) % 7) {
      previousDayNumber = dayToNumber(days[i]);
    } else {
      return false;
    }
  }
  return true;
};

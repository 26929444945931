import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { IYellowBusiness } from '@websites/model';
import { justTheText } from '@websites/utils';
import { ThemeContext } from '../ThemeContext';

export const HTMLPageHeader: FC<{
  title: string;
  business: IYellowBusiness;
  meta?: [];
  keywords?: string[];
}> = ({ title, isEcommerce, business, meta = [], keywords = [], metaDescription = '' }) => {

  let description = '';

  if (metaDescription) {
    description = metaDescription.slice(0, 150);
  } else {
    const getBusinessDescription = () => business.description ? justTheText(business.description).slice(0, 150) : '';
    description = getBusinessDescription();
  }

  const getImageForSocialCards = () => {
    if (business?.website?.coverImage) {
      return business?.website?.coverImage;
    }
    return null;
  };

  let socialMetaTags = [
    {
      name: `description`,
      content: description,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:title`,
      content: `${title} | ${business.name}`,
    },
    {
      property: `og:description`,
      content: description,
    },

    {
      property: 'og:url',
      content: `https://${process.env.GATSBY_WEBSITES_BUILD_URL}/`,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:creator`,
      content: business.name,
    },
    {
      name: `twitter:title`,
      content: `${title} | ${business.name} | ${description}`.slice(0, 55),
    },
    {
      name: `twitter:description`,
      content: description,
    },
  ];

  const socialImage = getImageForSocialCards();
  if (socialImage) {
    socialMetaTags.push(
      ...[
        {
          name: `twitter:image`,
          content: socialImage,
        },
        {
          property: 'og:image',
          content: socialImage,
        },
      ]
    );
  }

  if (keywords.length > 0) {
    socialMetaTags = [
      ...socialMetaTags,
      {
        name: `keywords`,
        content: keywords.join(`, `),
      },
    ];
  }

  if (meta.length > 0) {
    socialMetaTags = [
      ...socialMetaTags,
      ...meta,
    ];
  }

  const { bg, themeVariantHexColor } = React.useContext(ThemeContext);
  const googleFontPath = (isEcommerce) ? 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Trirong&display=swap' : 'https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap';

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      bodyAttributes={{
        class: bg('highlight-dark')
      }}
      title={title}
      titleTemplate={`%s | ${business.name}`}
    >
      {socialMetaTags.map((tag, tagIndex) => {
        return (tag.name)
          ? <meta key={tagIndex} name={tag.name} content={tag.content} />
          : <meta key={tagIndex} property={tag.property} content={tag.content} />
      })}

      {business?.website?.googleSiteVerificationId && (
        <meta name="google-site-verification" content={business?.website?.googleSiteVerificationId} />
      )}
      <meta name="theme-color" content={themeVariantHexColor('highlight-dark')} />
      {business?.website?.jsonLD && (
        <script type="application/ld+json">{`${JSON.stringify(
          business?.website?.jsonLD
        )}`}</script>
      )}

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link
          rel="preload"
          href={googleFontPath}
          as="style"
          onload="this.onload=null;this.rel='stylesheet'"
      />
      <noscript>
      {`
        <link
            href=${googleFontPath}
            rel="stylesheet"
            type="text/css"
        />
      `}
      </noscript>
    </Helmet>
  );
};


import React from 'react';

import { Section } from "../Section/Section";
import ContactForm from '../ContactForm';
import { onContactFormSubmit } from '@websites/utils';

export const SectionContact = ({section}) => {
  return (
    <Section section={section}>
      <ContactForm onSubmit={onContactFormSubmit} />
    </Section>
  )
}
